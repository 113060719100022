/* eslint-disable react/destructuring-assignment */
import type { ReactNode } from 'react'
import React from 'react'

import { Alert, Typography } from '@mui/material'

import type { ANY } from '@core/types'

class ErrorBoundary extends React.Component<
  {
    showOnProd?: boolean
    children: ReactNode
    showStack?: boolean
    onError?: (error: ANY, errorInfo: ANY) => void
    renderError?: (props: { error?: ANY; errorInfo?: ANY }) => ReactNode
  },
  ANY
> {
  constructor(props: ANY) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  render() {
    if (
      import.meta.env.PROD &&
      import.meta.env.VITE_DEPLOY_ENV === 'production' &&
      !this.props.showOnProd &&
      this.state.errorInfo
    ) {
      return null
    }

    if (this.state.errorInfo) {
      if (typeof this.props.renderError === 'function') {
        return this.props.renderError({
          error: this.state.error,
          errorInfo: this.state.errorInfo,
        })
      }

      return (
        <Alert severity="error">
          <Typography>
            {this.state.error && this.state.error.toString()}
          </Typography>
          {this.props.showStack && (
            <Typography variant="body2">
              {this.state.errorInfo.componentStack}
            </Typography>
          )}
        </Alert>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
