import { useCallback, useEffect, useMemo } from 'react'

import {
  LINKS,
  LOCAL_STORAGE_TOKEN,
  LOCAL_STORAGE_USER_EMAIL,
  LOCAL_STORAGE_USER_ID,
  LOCAL_STORAGE_V2_APP_STORE,
} from '@core/config'
import type { AppAuthQuery, Permission, UserRole } from '@core/graphql'
import { useAppAuthQuery } from '@core/graphql'

export type CurrentUser = NonNullable<AppAuthQuery['currentUser']>

const SIGN_OUT_DELAY = 1000 // Temporary delay to initialize Amplitude before redirecting to sign in page

export const useAuth = () => {
  const [{ fetching: isLoading, data }, refetch] = useAppAuthQuery({
    requestPolicy: 'cache-first',
  })

  const user = useMemo(() => data?.currentUser ?? null, [data?.currentUser])

  /** Checks if the current user has at least one role in `expectedRoles`. */
  const hasRole = useCallback(
    (...expectedRoles: (UserRole | null | undefined)[]) => {
      if (!user?.roles) {
        return false
      }

      return expectedRoles
        .filter((r) => !!r)
        .some((role) => user.roles.includes(role!))
    },
    [user?.roles],
  )

  const hasPermission = useCallback(
    (...permissions: (Permission | null | undefined)[]) => {
      if (!user?.permissions) {
        return false
      }

      return permissions
        .filter((p) => !!p)
        .some((permission) => user.permissions.includes(permission!))
    },
    [user?.permissions],
  )

  const signOut = useCallback(
    (next: string | null = window.location.pathname) => {
      localStorage.removeItem(LOCAL_STORAGE_USER_ID)
      localStorage.removeItem(LOCAL_STORAGE_USER_EMAIL)
      localStorage.removeItem(LOCAL_STORAGE_TOKEN)
      localStorage.removeItem(LOCAL_STORAGE_V2_APP_STORE)
      const params = new URLSearchParams(window.location.search)
      if (next && !params.get('next')) {
        params.append('next', next)
      }
      setTimeout(() => {
        window.location.href = `${LINKS.CREATE_ACCOUNT}?${params.toString()}`
      }, SIGN_OUT_DELAY)
    },
    [],
  )

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_USER_ID, user?.id || '')
    localStorage.setItem(LOCAL_STORAGE_USER_EMAIL, user?.email || '')
  }, [user])

  return {
    user,
    hasRole,
    hasPermission,
    isLoading,
    $user: user!,
    signOut,
    refetch,
  }
}
